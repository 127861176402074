<script lang="ts" setup>
import type { BreadcrumbItem } from '@/types/menu'

const route = useRoute()
const breadcrumbs = computed(() => (route.meta.breadcrumb || []).map((item: BreadcrumbItem) => {
  if (item.url && item.params?.length) {
    item.url = item.params?.reduce(
      (memo, param) => memo.replace(`:${param}`, route.params[param as keyof typeof route.params]),
      item.url,
    )
  }

  return item
}))
</script>

<template>
  <VBreadcrumbs class="layout-breadcrumb">
    <template v-for="(breadcrumb, index) in breadcrumbs" :key="index">
      <VBreadcrumbsItem class="v-breadcrumbs-item" :class="{ 'v-breadcrumbs-item--disabled': breadcrumb.active }">
        <NuxtLink v-if="breadcrumb.url" :to="breadcrumb.url">
          {{ breadcrumb.i18n ? $t(breadcrumb.i18n) : breadcrumb.title }}
        </NuxtLink>
        <span v-else>{{ breadcrumb.i18n ? $t(breadcrumb.i18n) : breadcrumb.title }}</span>
      </VBreadcrumbsItem>
      <template v-if="index < breadcrumbs.length - 1">
        <v-breadcrumbs-divider>
          <VIcon icon="tabler-chevron-right" />
        </v-breadcrumbs-divider>
      </template>
    </template>
  </VBreadcrumbs>
</template>

<style lang="scss">
.layout-breadcrumb {
  font-size: 12px;

  .v-breadcrumbs-item {
    --at-apply: ktext-primary;

    .v-breadcrumbs-item--link {
      &:hover {
        text-decoration: unset;
      }
    }

    &.v-breadcrumbs-item--disabled {
      a,
      span {
        color: rgb(var(--v-theme-base-color-black));
      }
    }
  }

  .v-breadcrumbs-divider {
    padding-block: 0;
    padding-inline: 6px;

    .tabler-chevron-right {
      font-size: 12px;
    }
  }
}
</style>
