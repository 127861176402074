import type { ResponseError, ResponseSuccess } from '@/types/http'
import type { CommonRole, LoggedInCompany, LoggedInInvestor, LoggedInUser } from '@/types/user'
import { StorageKey } from '@/types/storage'
import { UserDashboard } from '@/types/user'
import { uniqBy } from 'lodash-es'

export const useAuthStore = defineStore('auth', () => {
  const subscriptionStore = useSubscriptionStore()

  const currentUser = ref<LoggedInUser>()

  const currentCompany = ref<LoggedInCompany>()
  const currentInvestor = ref<LoggedInInvestor>()
  const { currentManagedCompanies, currentManagedInvestors, switchableRoles } = useRole(currentUser)

  const lastUserInfo = useLocalStorage<{ lastUserId: number, lastRoleId?: number | string | null } | undefined>(StorageKey.LAST_USER, {
    lastUserId: 0,
  })
  const lastDashboard = useLocalStorage(StorageKey.LAST_DASHBOARD, 'external')
  const currentDashboard = computed<UserDashboard>(() => {
    if (!currentUser.value)
      return UserDashboard.EXTERNAL

    const isExistOldUser = lastUserInfo.value?.lastUserId === currentUser.value?.id

    return getDashboard(currentUser.value, isExistOldUser ? lastDashboard.value : '')
  })
  const digitLength = computed((): number => {
    switch (currentDashboard.value) {
      case UserDashboard.ISSUERS: {
        return currentCompany.value?.display_decimal_places || 4
      }
      case UserDashboard.INVESTORS: {
        return currentInvestor.value?.display_decimal_places || 4
      }
      default:
        return 0
    }
  })

  const currentPermissions = computed(
    () => normalizePermissions(
      currentUser.value
        ? getPermissions(currentUser.value)
        : [],
    ),
  )

  const currentRoleId = computed(() => {
    switch (currentDashboard.value) {
      case UserDashboard.ISSUERS:
        return !lastUserInfo.value?.lastRoleId
          ? currentManagedCompanies.value[0].id
          : currentManagedCompanies.value.some(item => item.id === lastUserInfo.value?.lastRoleId)
            ? lastUserInfo.value.lastRoleId
            : currentManagedCompanies.value[0].id
      case UserDashboard.INVESTORS:
        return !lastUserInfo.value?.lastRoleId
          ? currentManagedInvestors.value[0].id
          : currentManagedInvestors.value.some(item => item.id === lastUserInfo.value?.lastRoleId)
            ? lastUserInfo.value?.lastRoleId
            : currentManagedInvestors.value[0].id
      default:
        return null
    }
  })

  const accessToken = useLocalStorage(StorageKey.ACCESS_TOKEN, '')

  const isAuthenticated = computed(() => Boolean(accessToken.value))

  async function getDashboardData() {
    switch (currentDashboard.value) {
      case UserDashboard.ISSUERS: {
        if (currentRoleId.value && currentRoleId.value !== currentCompany.value?.id) {
          await Promise.all([
            getCompany(currentRoleId.value),
            subscriptionStore.getSubscription(Number(currentRoleId.value)),
          ])
        }
        break
      }
      case UserDashboard.INVESTORS: {
        if (currentManagedInvestors.value.length === 0)
          await getInvestors()
        if (currentRoleId.value && currentRoleId.value !== currentInvestor.value?.id)
          await getInvestor(currentRoleId.value)
        break
      }
    }
  }

  watch(
    () => [currentRoleId.value, currentUser.value?.id],
    (newValue, oldValue) => {
      if (oldValue[0] || oldValue[1]) // ignore initial load (from null and undefined), only get new data when switching roles
        getDashboardData()
    },
    { flush: 'post' },
  )

  const isIssuer = computed(() => currentUser.value?.is_issuer || Number(currentUser.value?.manage_companies?.length) > 0)

  const isInvestor = computed(() => currentUser.value?.is_investor || Number(currentUser.value?.manage_investors?.length) > 0)

  const isBoardMember = computed(() => currentUser.value?.is_board_member)

  const isProxy = computed(() => currentUser.value?.is_proxy)

  const isGuest = computed(() => currentUser.value?.is_guest)

  async function getUser() {
    try {
      if (currentUser.value)
        return currentUser.value

      const response = await $api<ResponseSuccess<LoggedInUser>>('/user')

      currentUser.value = response.data

      return response.data
    }
    catch (error: any) {
      return error.data as ResponseError
    }
  }

  function updateUserProfile(user: LoggedInUser) {
    if (currentUser.value) {
      currentUser.value = { ...currentUser.value, ...user }
    }
    return currentUser.value
  }

  async function getVerifyEmail(params: {
    id: string
    hash: string
    expires: string
    signature: string
  }) {
    try {
      const response = await $api<ResponseSuccess<{ item: { email: string } }>>(`email/verify/${params.id}/${params.hash}`, {
        params: {
          expires: params.expires,
          signature: params.signature,
        },
      })

      return response
    }
    catch (error: any) {
      return error.data as ResponseError
    }
  }

  async function postResendVerification(payload: {
    email: string
  }) {
    try {
      const response = await $api<ResponseSuccess>('email/resend', {
        method: 'POST',
        body: payload,
      })

      return response
    }
    catch (error: any) {
      return error.data as ResponseError
    }
  }

  async function postLogin(payload: { email: string, password: string, remember?: boolean, one_time_password?: string }) {
    try {
      const apiUrl = payload.one_time_password ? '/2fa-check-otp' : '/login'
      const response = await $api<ResponseSuccess<{
        access_token: string
        user: LoggedInUser
      }>>(apiUrl, {
        method: 'POST',
        body: payload,
      })

      currentUser.value = response.data.user

      accessToken.value = response.data.access_token

      return response
    }
    catch (error: any) {
      return error.data as ResponseError<{ value: 'CREDENTIALS_INVALID' }>
    }
  }

  async function postLogout() {
    try {
      currentUser.value = undefined
      currentCompany.value = undefined
      currentInvestor.value = undefined

      accessToken.value = ''

      await $api('/logout', {
        method: 'POST',
      })
    }
    catch {}

    return navigateTo({ name: 'auth-login', query: { logout: 'true' } })
  }

  async function postRegister(payload: {
    first_name: string
    last_name: string
    display_name: string
    email: string
    password: string
    password_confirmation: string
  }) {
    try {
      const response = await $api<ResponseSuccess>('/register', {
        method: 'POST',
        body: payload,
      })

      return response
    }
    catch (error: any) {
      return error.data as ResponseError
    }
  }

  async function postRegisterCompany(payload: {
    address: string
    address_line2: string
    canton: string
    city: string
    country_id: number
    currency_id: number
    email: string
    first_name: string
    last_name: string
    name: string
    password: string
    password_confirmation: string
    phone: string
    type_id: number
    uid_number: string
    zip: string
    onboarding_small_shareholders: boolean
    has_auditors: boolean
  }) {
    try {
      const response = await $api<ResponseSuccess>('/new-company', {
        method: 'POST',
        body: payload,
      })

      return response
    }
    catch (error: any) {
      return error.data as ResponseError
    }
  }

  async function postForgotPassword(payload: { email: string, returnUrl: string }) {
    try {
      const response = await $api<ResponseSuccess>('/forgot-password', {
        method: 'POST',
        body: payload,
      })

      return response
    }
    catch (error: any) {
      return error.data as ResponseError
    }
  }

  async function postResetPassword(payload: {
    email: string
    password: string
    password_confirmation: string
    token: string
  }) {
    try {
      const response = await $api<ResponseSuccess>('/reset-password', {
        method: 'POST',
        body: payload,
      })

      return response
    }
    catch (error: any) {
      return error.data as ResponseError
    }
  }

  async function postCreatePassword(payload: {
    email: string
    password: string
    password_confirmation: string
    token: string
  }) {
    try {
      const response = await $api<ResponseSuccess>('/create-password', {
        method: 'POST',
        body: payload,
      })

      return response
    }
    catch (error: any) {
      return error.data as ResponseError
    }
  }

  async function postCheckEmailCreatedPassword(payload: {
    email: string
    token: string
  }) {
    try {
      const response = await $api<ResponseSuccess>('/create-password', {
        method: 'POST',
        body: payload,
      })

      return response
    }
    catch (error: any) {
      if (error?.response.status === 422)
        return error.data as ResponseError<{ email?: string[], password?: string[] }>

      return error.data as ResponseError
    }
  }

  async function getCompany(companyId: number | string) {
    try {
      const response = await $api<ResponseSuccess<LoggedInCompany>>(`/companies/${companyId}`)

      currentCompany.value = response.data || {}
    }
    catch (error) {
      console.log(error)
    }
  }

  function updateCompany(company: LoggedInCompany) {
    if (currentCompany.value) {
      currentCompany.value = { ...currentCompany.value, ...company }
    }
    return currentCompany.value
  }

  async function getInvestor(investorId: number | string) {
    try {
      const response = await $api<ResponseSuccess<LoggedInInvestor>>(`/investors/${investorId}`)

      currentUser.value = <LoggedInUser>{ ...currentUser.value, own_investors: uniqBy([
        ...(currentUser.value?.own_investors || []),
        response.data,
      ], 'id') }

      currentInvestor.value = response.data
    }
    catch (error) {
      console.log(error)
    }
  }

  async function getInvestors() {
    try {
      const response = await $api<ResponseSuccess<LoggedInInvestor[]>>('/investors')
      const investors = response.data

      if (investors?.length) {
        currentUser.value = <LoggedInUser>{ ...currentUser.value, own_investors: investors }

        if (!currentInvestor.value) {
          await getInvestor(investors[0].id)
        }
      }
    }
    catch {

    }
  }

  async function switchRole(role: CommonRole) {
    if (!currentUser.value)
      return

    lastDashboard.value = role.dashboard

    let roleId = null

    switch (currentDashboard.value) {
      case UserDashboard.ISSUERS: {
        roleId = role.id
        break
      }
      case UserDashboard.INVESTORS: {
        roleId = role.id
        break
      }
    }

    lastUserInfo.value = {
      lastUserId: currentUser.value.id,
      lastRoleId: roleId,
    }
  }

  return {
    currentUser,
    currentRoleId,
    currentDashboard,
    currentCompany,
    currentInvestor,
    currentPermissions,
    switchableRoles,
    isAuthenticated,
    isIssuer,
    isInvestor,
    isBoardMember,
    isProxy,
    isGuest,
    digitLength,
    getDashboardData,
    getUser,
    getInvestor,
    getVerifyEmail,
    postResendVerification,
    postLogin,
    postLogout,
    postRegister,
    postRegisterCompany,
    postForgotPassword,
    postResetPassword,
    postCreatePassword,
    postCheckEmailCreatedPassword,
    switchRole,
    updateUserProfile,
    getCompany,
    updateCompany,
  }
})
