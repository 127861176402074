export enum UserDashboard {
  INVESTORS = 'investors',
  ISSUERS = 'issuers',
  BOARD_MEMBER = 'board-member',
  EXTERNAL = 'external',
  PROXY = 'proxy',
}

export enum USER_TYPES {
  INVESTOR = 'INVESTOR',
  INTERNAL = 'INTERNAL',
  EXTERNAL_GUEST = 'EXTERNAL',
  BOARD_MEMBER = 'BOARD_MEMBER',
}

export interface Owner {
  id: number
  digishares_id: number | null
  first_name: string
  last_name: string
  display_name: string
  email: string
  email_verified_at: string
  date_of_birth: string | null
  locale: string
  is_registration_done: boolean
  is_admin: boolean
  is_issuer: boolean
  is_investor: boolean
  is_board_member: boolean
  is_proxy: boolean
  is_guest: boolean
  is_latest_release: boolean
  contact_id: number
  photo_url: string | null
  uses_two_factor_auth: number
  created_at: string | null
  updated_at: string | null
  deleted_at: string | null
}

export interface LoggedInUser {
  id: number
  digishares_id: null
  first_name: string
  last_name: string
  display_name: string
  email: string
  email_verified_at: Date
  date_of_birth?: string
  locale: string
  is_registration_done: boolean
  is_admin: boolean
  is_issuer: boolean
  is_investor: boolean
  is_board_member: boolean
  is_proxy: boolean
  is_guest: boolean
  is_latest_release: boolean
  contact_id: number
  photo_url: string
  uses_two_factor_auth: number
  created_at: Date
  updated_at: Date
  deleted_at: null
  contact: Contact
  own_companies: ManageCompany[]
  own_investors: ManageCompany[]
  manage_companies: ManageCompany[]
  manage_investors: ManageInvestor[]
  company_boards: CompanyBoard[]
  skip_verification: boolean
}

export interface CompanyBoard {
  id: number
  company_id: number
  user_id: number
  role_id: number
  signatory_power: string
  created_at: Date
  updated_at: Date
  deleted_at: null
  email: string
  company: LoggedInCompany
}

export interface LoggedInCompany {
  id: number
  digishares_id: null
  uid_number: null | string
  user_id: number
  name: string
  name_slug: string
  type_id: number
  currency_id: number
  logo_url: null | string
  domain: null | string
  contact_id: number
  details?: string
  display_decimal_places: number | null
  is_registration_done: boolean
  partner: null
  onboarding_small_shareholders: boolean
  has_auditors: boolean
  cancellation_subscription_at: Date | null
  cancellation_account_at: Date | null
  voucher_code?: string
  created_at: Date
  updated_at: Date
  deleted_at: null
  contact: Contact
  owner?: Owner
}

export interface Contact {
  id: number
  first_name: string
  last_name: string
  phone: string
  email: string | null
  date_of_birth: string | null
  address: string
  address_line2: string | null
  zip: string | null
  city: string | null
  nationality: string | null
  country_id: number
  canton: string | null
  created_at: Date
  updated_at: Date
  deleted_at: string | null
  bank_name: string | null
  bank_place: string | null
  iban: string | null
}

export interface ManageCompany {
  id: number
  name: string
  logo_url: null | string
  is_registration_done?: boolean
  pivot?: Pivot
}

export interface Pivot {
  user_id: number
  company_id?: number
  id: number
  is_confirmed: number
  investor_id?: number
}

export interface ManageInvestor {
  id: number
  name: string
  logo_url: null
  pivot?: Pivot
}

export interface CommonRole {
  id: number | string
  key: string
  originName?: string
  name: string
  logo_url?: string | null | undefined
  dashboard: 'issuers' | 'board-member' | 'external' | 'investors' | 'proxy'
}

export interface LoggedInInvestor {
  id: number
  digishares_id: string | null
  uid_number: string | null
  user_id: number
  name: string
  name_slug: string
  type_id: number
  category_id: number
  contact_id: number
  logo_url: string
  details: string | null
  display_decimal_places: number | null
  invited_at: string
  created_at: string
  updated_at: string
  deleted_at: string | null
  email: string
  full_name: string
  beneficial_owners: any[]
  contact: Contact
  owner: Owner
}

export interface UserProfileRequest {
  password: string
  first_name: string
  last_name: string
  display_name: string
  date_of_birth?: string
  photo_url?: string
  phone?: string
  address?: string
  address_line2?: string
  zip?: string
  city?: string
  country_id?: number
}

export interface UpdatePasswordRequest {
  current_password: string
  new_password: string
  new_password_confirmation: string
}

export interface userTwoFa {
  qr_code: string
  secret: string
}
