import type { RestrictEditingTransferLog } from '@/types/transfer'
import mitt from 'mitt'

// eslint-disable-next-line ts/consistent-type-definitions
type ApplicationEvents = {
  'topics:switch-topic': string
  'meeting:fetch-meeting': void
  'transfer-shares:card-loading': boolean
  'transfer-shares:restric-editing': RestrictEditingTransferLog | undefined
  'share-ledger:card-loading': boolean
}

export default defineNuxtPlugin(() => {
  const emitter = mitt<ApplicationEvents>()

  return {
    provide: {
      bus: {
        $on: emitter.on,
        $emit: emitter.emit,
        $off: emitter.off,
      },
    },
  }
})
