<script setup lang="ts">
interface Props {
  location?: any
}

const props = withDefaults(defineProps<Props>(), {
  location: 'bottom end',
})

const { locale, locales, localeProperties, setLocale } = useI18n({ useScope: 'global' })
</script>

<template>
  <AppButton
    color="secondary"
    variant="plain"
    size="default"
    class="k-0csi4k"
  >
    <VIcon
      size="24"
      icon="tabler-world"
      class="k-plh3q3"
    />
    <span class="k-r684ya">
      {{ localeProperties.name || locale }}
    </span>

    <VMenu
      activator="parent"
      :location="props.location"
      offset="12px"
      class="k-m4jj8t"
    >
      <!-- List -->
      <VList
        :selected="[locale]"
        color="primary"
        min-width="120px"
      >
        <!-- List item -->
        <VListItem
          v-for="lang in locales"
          :key="lang.code"
          class="k-rfs6ed"
          :value="lang.code"
          @click="setLocale(lang.code)"
        >
          <!-- Language label -->
          <VListItemTitle class="k-gvldqo">
            {{ lang.name || lang.code }}
          </VListItemTitle>
        </VListItem>
      </VList>
    </VMenu>
  </AppButton>
</template>
